<template>
  <section class="slider">
    <b-carousel id="carousel-1" v-model="slide" :interval="interval" controls indicators background="#ababab"
      img-width="1024" img-height="490" style="text-shadow: 1px 1px 2px #333" @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd">
      <span v-for="item in bannerSlides" v-bind:key="item.id">
        <a href="#" @click.prevent="handleSlideClick(item)" target="_self">
          <b-carousel-slide :img-src="item.src">
            <h2>{{ item.title }}</h2>
          </b-carousel-slide>
        </a>
      </span>
    </b-carousel>
    <slot>
      <a href="#" class="apause" @click.prevent="togglePause">
        <pause v-if="interval > 0"></pause>
        <play v-if="interval === 0"></play>
      </a>
    </slot>
  </section>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import constantData from '@/json/data.json'
import { transferAuthTokenSecurely, isLinkCampMeetingLibrary } from '@/services/CampMeetingLibraryAuthService.js'

export default {
  data() {
    return {
      interval: 4000,
      link_pieces: [],
      slide: 0,
      sliding: null,
    }
  },
  components: {
    pause: () => import('@/assets/svgs/pause.vue'),
    play: () => import('@/assets/svgs/play.vue'),
  },
  mounted() {
    // link on the first slide was throwing an error
    setTimeout(() => { this.onSlideStart(0) }, 500)
  },
  computed: {
    ...mapGetters({
      bannerSlides: 'home/bannerSlides',
      userId: 'user/userId'
    }),
  },
  methods: {
    ...mapActions({
      setMRPProgramId: 'mrp/setMRPProgramId',
      setMRPSelectedCategory: 'mrp/setMRPSelectedCategory',
      setMRPSelectedTopicId: 'mrp/setMRPSelectedTopicId',
      setMRPSelectedVideoId: 'mrp/setMRPSelectedVideoId',
      setSelectedAnnouncementKey: 'user/setSelectedAnnouncementKey',
      setSelectedArticleKey: 'user/setSelectedArticleKey',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setStoreSelectedCategory: 'gideonStore/setStoreSelectedCategory',
      setStoreSelectedProductId: 'gideonStore/setStoreSelectedProductId',
      setStoreSelectedProductKey: 'gideonStore/setStoreSelectedProductKey',
      setStoreSelectedSubCategoryId: 'gideonStore/setStoreSelectedSubCategoryId'
    }),
    async onSlideStart(slide) {
      this.sliding = true
      // when the slide is in view
      this.link_pieces = !!this.bannerSlides[slide] ? this.bannerSlides[slide].link.split('|') : ['']
      // if this is a link to an internal page...
      let lp = constantData.internal_link_pages.find((il) => il.name === this.link_pieces[0])
      if (!!lp) {
        switch (lp.name) {
          case 'announcement-detail':
            await this.setSelectedAnnouncementKey(this.link_pieces[1])
            break
          case 'member-payment-center':
            await this.setSelectedIndividualKey(this.userId)
            break
          case 'success-story':
          case 'testimony':
            await this.setSelectedArticleKey(this.link_pieces[1])
            break
          case 'mrp-program':
            await this.setMRPProgramId(this.link_pieces[1])
            break
          case 'mrp-videos':
          case 'mrp-links':
          case 'mrp-topics':
          case 'mrp-topic-detail':
            await this.setMRPProgramId(this.link_pieces[1])
            await this.setMRPSelectedCategory(this.link_pieces[2])
            await this.setMRPSelectedTopicId(this.link_pieces[3] || '')
            break
          case 'store-product-details':
            await this.setStoreSelectedProductId(this.link_pieces[1])
            await this.setStoreSelectedProductKey(this.link_pieces[2])
            break
          case 'store-category-products':
            await this.setStoreSelectedSubCategoryId(this.link_pieces[1])
            break
          case 'store-home':
            await this.setStoreSelectedCategory(this.link_pieces[1])
            break
        }
      } else {
        await this.setSelectedAnnouncementKey('')
        await this.setSelectedArticleKey('')
        await this.setStoreSelectedProductId('')
        await this.setStoreSelectedProductKey('')
        await this.setMRPProgramId('')
        await this.setMRPSelectedCategory('')
        await this.setMRPSelectedTopicId('')
      }
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
    togglePause() {
      this.interval = this.interval === 0 ? 4000 : 0
    },
    handleSlideClick(itm) {
      let ip = constantData.internal_link_pages.find((ilp) => ilp.name === this.link_pieces[0])
      if (!!ip) {
        this.$router.push({ name: ip.name })
      } else if (isLinkCampMeetingLibrary(itm.link)) {
        let extUrl = itm.link.replace('[ind_key]', this.userId)
        transferAuthTokenSecurely(extUrl)
      } else {
        window.open(itm.link, '_blank')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/components/component-carousel.scss';

.apause {
  z-index: 15;
  position: relative;
  left: 49.4%;
  cursor: pointer;

  @include breakpoint(sm) {
    height: 6px;
    width: 6px;
    left: 248px;
    top: -57px;
    margin: 0 5px;

    svg {
      width: 6px;
      height: 6px;
    }
  }
}
</style>
